.animate{
 font-family: "arial";
 font-size: 1em;
 white-space: nowrap;
 overflow: hidden;
 width: 100%;
 animation: animtext 2s steps(80, end); 
  transition: all cubic-bezier(0.1, 0.7, 1.0, 0.1);
}
@keyframes animtext { 
 from {
     width: 0;
    transition: all 2s ease-in-out;
 } 
}

.cursor {
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
  }

  #content-wrapper {
    display: flex;
}

#content,
#aside {
    flex-grow: 1;
    overflow: auto;
    resize: horizontal;
    min-width: 100px; /* Minimum width to prevent collapsing */
}

.content-item {
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}
